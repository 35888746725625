import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Select from 'react-select';

//Components
import { Spinner } from '../index';
import styles from './PreEnroll.module.css';

import toast from 'react-hot-toast';

//Serivce APIs
import { preEnroll } from '../../utils/services';

function PreEnrollForm({ isLoading }) {
  const [mobileNumber, setMobileNumber] = useState('');
  const [email, setEmail] = useState(null);
  const [fullName, setFullName] = useState('');
  const [fullAddress, setFullAddress] = useState('');
  const [memberID, setMemberID] = useState('');
  const [selectedSubDomain, setSelectedSubDomain] = useState(null);

  const options = [
    { value: 'cozera', label: 'Cozera' },
    { value: 'demo1', label: 'Enterprise 1' },
    { value: 'demo2', label: 'Enterprise 2' },
    { value: 'demo3', label: 'Enterprise 3' },
    { value: 'advantiscu', label: 'Advantis CU' },
    { value: 'rivermarkcu', label: 'Rivermark CU' },
    { value: 'unituscu', label: 'Unitus CU' },
    { value: 'pioneerfcu', label: 'Pioneer FCU' },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedSubDomain?.value == undefined) {
      toast.error('Please select a Relying Party.');
      return;
    }
    const data = { fullAddress, memberID };
    const response = await preEnroll({mobileNumber, email, fullName, data, subdomain: selectedSubDomain?.value});
    if (response?.status!==201) {
      toast.error('Pre-Enrolle failed.');
      return;
    }
    toast.success('Member has been successfully Pre-Enrolled');
  };

  const handleMobileNumber = async (e) => {
    setMobileNumber(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleFullName = (e) => {
    setFullName(e.target.value);
  };

  const handleFullAddress = (e) => {
    setFullAddress(e.target.value);
  };

  const handleMemberID = (e) => {
    setMemberID(e.target.value);
  };
  
  return (
    <React.Fragment>
      {isLoading && <Spinner />}
      <Form className={styles.FormContainer} onSubmit={handleSubmit} noValidate>
        <div className={styles.InputContainer}>

          <div className={styles.Label}>Relying Party</div>
          <Select
            options={options}
            defaultValue={selectedSubDomain}
            onChange={setSelectedSubDomain}
          />

          <Form.Group className='mb-2' controlId='preEnrollForm.ControlInputMobileNumber'>
            <div className={styles.Label}>Enter Mobile Number</div>
            <input
              type='tel'
              placeholder='Enter Mobile Number'
              className={styles.Input}
              maxLength='15'
              minLength='15'
              onChange={handleMobileNumber}
              value={mobileNumber}
              required
              disabled={isLoading}
            />
          </Form.Group>

          <Form.Group className='mb-2' controlId='preEnrollForm.ControlInputPassword'>
            <div className={styles.Label}>Full Name</div>
            <input
              type='text'
              placeholder='John Doe'
              className={styles.Input}
              onChange={handleFullName}
              value={fullName}
              required
            />
          </Form.Group>

          <Form.Group className='mb-2' controlId='preEnrollForm.ControlInputMobileNumber'>
            <div className={styles.Label}>E-mail</div>
            <input
              type='text'
              placeholder='tester@fintech.io'
              className={styles.Input}
              onChange={handleEmail}
              value={email}
            />
            <Form.Control.Feedback type='invalid'>
              Please provide a valid email.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='mb-2' controlId='preEnrollForm.ControlInputPassword'>
            <div className={styles.Label}>Full Address</div>
            <input
              type='Text'
              placeholder='123 SW Main St. Maldives, Maldives'
              className={styles.Input}
              onChange={handleFullAddress}
              value={fullAddress}
              autoComplete='on'
            />
          </Form.Group>

          <Form.Group className='mb-2' controlId='preEnrollForm.ControlInputPassword'>
            <div className={styles.Label}>Member ID</div>
            <input
              type='Text'
              placeholder='123ABC'
              className={styles.Input}
              onChange={handleMemberID}
              value={memberID}
              autoComplete='on'
            />
          </Form.Group>

        </div>
        <Button variant='primary' type='submit' className={styles.Button}>
          PreEnroll
        </Button>
      </Form>
    </React.Fragment>
  );
}

export default PreEnrollForm;
