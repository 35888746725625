import React from 'react';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { login } from '../../utils/session';
import { signInIdgoAuthUrl, signInIdgoAuthStatus } from '../../utils/services';

import toast from 'react-hot-toast';
import styles from './SignInFido.module.css';

import IdgoAuthComponent from '../../IdgoAuthComponent';
import ReactDOM from 'react-dom';
const MyReactIdgoAuthComponent = IdgoAuthComponent.driver('react', {React, ReactDOM});

const maxWaitSec = 180 * 1000; // 3 minutes

function SignInFido(props) {
  const navigate = useNavigate();

  const centerPopupWin = (url, name, w, h) => {
    const left = window.screen.availLeft + (window.screen.availWidth - w) / 2;
    const top = window.screen.availTop + (window.screen.availHeight - h) / 2;
    return window.open(url, name, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  };

  // adding this alert and allows triggering the popup window from a non-async function
  const createSecurityKeyAlert = (userAuthUrl, verificationRequestId) => {
    confirmAlert(
      { title: 'Security Key not found'
      , message: 'Would you like to create a Security Key for this device?'
      , buttons: [
        { label: 'Yes'
        , onClick: () => { handleIdgoLoginByPopup(userAuthUrl, verificationRequestId) }
        },
        { label: 'No'
        , onClick: () => { props.setLoading(false); }
        }
      ]
    });
  };

  // To enable Safari (iOS & MAC) to open a popup window this function must not be async
  const handleIdgoLoginByPopup = (userAuthUrl, verificationRequestId) => {
    const authWin = centerPopupWin(userAuthUrl, 'IDgo', 400, 600);
    const timerStart = Date.now();
    getAuthStatus(authWin, verificationRequestId, timerStart);
  };

  // this function allows us to recursivly call the auth status api to retry 'timeout' result
  const getAuthStatus = (authWin, verificationRequestId, timerStart) => {
    signInIdgoAuthStatus(verificationRequestId)
      .then((res) => {
        const timerEnd = Date.now();
        const duration = timerEnd - timerStart;
        if (res?.data?.result==='timeout' && duration <= maxWaitSec) {
          getAuthStatus(authWin, verificationRequestId, timerStart);
        } else {
          props.setLoading(false);
          authWin.close();
          handleAuthResponse(res);
        }
      })
      .catch((err) => {
        props.setLoading(false);
        authWin.close();
        toast.error(`Error: ${err}`, {duration: 7000});
      });
  };

  /**
   * This function initiates an authentication request and returns the userAuthUrl and verificationRequestId to the IdgoAuthComponent.
   * On success, the IdgoAuthComponent will call onAuthenticationResult() prop, which calls handleIdgoLoginByComponentResult().
   * Both of these call back functions don't have access to the react state. Therefor the 'loginValue' is passed down to the IdgoAuthComponent
   * so it can pass it back to the handleIdgoLoginByComponent() function. Similarly the userAuthUrl and verificationRequestId are passed to the
   * IdgoAuthComponent so it can pass these back to the handleIdgoLoginByComponentResult() function.
   */
  const handleIdgoLoginByComponent = async (loginValue) => {
    const resp = await signInIdgoAuthUrl(loginValue, 'qrcode');
    if (resp?.status === 404) {
      toast.error('Your account is not protected by IDgo. Please sign-in using a password and then activate IDgo.', {duration: 7000});
      return null;
    }
    if (resp?.status !== 200) {
      toast.error(`Error: ${resp.message}`, {duration: 7000});
      return null;
    }
    props.setLoading(true);
    return {userAuthUrl: resp.userAuthUrl, verificationRequestId: resp.id};
  };

  const handleIdgoLoginByComponentResult = async (code, message, userAuthUrl, verificationRequestId) => {
    let response;
    if (code===0) {
      response = await signInIdgoAuthStatus(verificationRequestId);
    } else {
      // code=5:enrollment not complete
      // code=6:auth aborted due to startAuthentication() error - possibly no FIDO key on this device
      // code=16:no fido marker cookie - assuming no Passkey
      if (code===5 || code===6 || code===16) { 
        createSecurityKeyAlert(userAuthUrl, verificationRequestId);
        return;
      } else {
        toast.error(`Error: ${message}`,{duration: 4000});
        props.setLoading(false);
        return;
      }
    }
    props.setLoading(false);
    handleAuthResponse(response);
  };

  const handleAuthResponse = (response) => {
    if (response?.status !== 200) {
      toast.error(`Error: ${response?.message}`,{duration: 7000});
      return;
    }
    if (response?.data?.result === 'rejected') {
      toast.error('Authentication declined');
      return;
    }
    if (response?.data?.result === 'timeout') {
      toast.error('Authentication timeout');
      return;
    }
    if (response?.data?.result !== 'pass') {
      toast.error('Authentication error');
      return;
    }

    login(response?.data?.accessToken);
    navigate('/accounts', {state:{homePage:props.homePage}});
  };

  const renderContent = (styles) => {
    const disabled = 'validEmail' in props ? !props.validEmail : false;
    const stylesIDgoEnabled = JSON.stringify(
      { width: '100%'
      , borderRadius: '0.375rem'
      , lineHeight: '1.5rem'
      , backgroundColor: '#4A7CB7'
      , border: '#4A7CB7 solid 1px'
      , cursor: 'pointer'
      , color: '#fff'
      , fontSize: '16px'
      , fontFamily: 'Poppins, sans-serif'
      , fontWeight: '400'
      , padding: '0.375rem 0.75rem'
      });
    const stylesIDgoDisabled = JSON.stringify(
      { width: '100%'
      , borderRadius: '0.375rem'
      , lineHeight: '1.5rem'
      , backgroundColor: '#4170a5'
      , opacity: '0.65'
      , border: '#4170a5 solid 1px'
      , color: '#fff'
      , fontSize: '16px'
      , fontFamily: 'Poppins, sans-serif'
      , fontWeight: '400'
      , padding: '0.375rem 0.75rem'
      });
    return <>
      <div className={styles.buttonWrapper}>
        <MyReactIdgoAuthComponent
          disabled={disabled}
          buttonStyles={disabled ? stylesIDgoDisabled : stylesIDgoEnabled}
          buttonText={'Log in with IDgo'}
          loginValue={props.email}
          onClick={handleIdgoLoginByComponent}
          onAuthenticationResult={handleIdgoLoginByComponentResult}
        ></MyReactIdgoAuthComponent>
      </div>
    </>
  };

  return (
    <React.Fragment>
      { renderContent(styles) }
    </React.Fragment>
  );
}

export default SignInFido;