import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

//Components
import styles from './AgentPopupCallback.module.css';

//Utilities
import { popupIDgoAgent } from '../../utils/idgoAgentPopup';

const options = [
  { value: 'cozera', label: 'Cozera' },
  { value: 'demo1', label: 'Enterprise 1' },
  { value: 'demo2', label: 'Enterprise 2' },
  { value: 'demo3', label: 'Enterprise 3' },
  { value: 'unituscu', label: 'Unitus CU' },
];

function AgentPopupCallback({ isLoading }) {
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState('5037092934');
  const [selectedSubDomain, setSelectedSubDomain] = useState(options[1]);

  const navAgentCallbackPage = () => { navigate('/agent-iframe') }

  const handleIDgoAgentResponse = (result, data) => {
    switch (result) {
      case 'browser-closed':
        window.alert(`IDgo Agent window closed`);
        break;
      case 'pass':
        window.alert(`result: ${result}, data: ${JSON.stringify(data,null,3)}`);
        break;
      case 'rejected':
        window.alert(`result: ${result}`);
        break;
      case 'fail':
        window.alert(`result: ${result}`);
        break;
      default:
        window.alert(`unknown result: ${result}`);
    }
  };

  const handleIDgoAgentPopup = () => {
    const subdomain = selectedSubDomain?.value;
    if (!mobileNumber) {
      window.alert('Please enter a mobile number.');
      return;
    }

    popupIDgoAgent(subdomain, mobileNumber, handleIDgoAgentResponse);
  };

  const handleMobileNumber = async (e) => {
    setMobileNumber(e.target.value);
  };

  return (
    <>
      <div className={styles.nav} onClick={navAgentCallbackPage}>
        i-frame
      </div>

      <Form className={styles.FormContainer} noValidate>
        <div className={styles.InputContainer}>

          <div className={styles.Label}>Relying Party</div>
          <Select
            options={options}
            defaultValue={options[1]}
            onChange={setSelectedSubDomain}
          />

          <Form.Group className='mb-2' controlId='preEnrollForm.ControlInputMobileNumber'>
            <div className={styles.Label}>Enter Mobile Number</div>
            <input
              type='tel'
              placeholder='Enter Mobile Number'
              className={styles.Input}
              maxLength='15'
              minLength='15'
              onChange={handleMobileNumber}
              value={mobileNumber}
              required
              disabled={isLoading}
            />
          </Form.Group>

          <div className={styles.AgentPopupLink} onClick={handleIDgoAgentPopup}>IDgo Agent</div>

        </div>

      </Form>
    </>
  );
}


export default AgentPopupCallback;
