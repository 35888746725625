import axios from 'axios';
import { ctxValue } from './config';
import { tokenVerify } from './session';

//SignIn Password
export async function signInPassword(email, password) {
  return axios
    .post(
      ctxValue('ATO_DEMO_SERVICE') + '/authenticateByPassword',
      { email, password },
      { headers: { 'Content-Type': 'application/json' } }
    )
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.error(`utils/services.js: signInPassword() - ${err}`);
      return { status: 500, message: 'System issue' };
    });
}

//SignIn SMS
export async function signInIdgoSms(email) {
  return axios
    .post(
      ctxValue('ATO_DEMO_SERVICE') + '/authenticateByIdgoEmail',
      { email },
      { headers: { 'Content-Type': 'application/json' } }
    )
    .then((result) => {
      return result;
    })
    .catch((err) => {
      if (err?.response?.status!==404) {
        console.error(`utils/services.js: signInIdgoSms() - ${err}`);
        return{ status: 500, message: 'System issue' };
      }
      return { status: 404, message: 'Account not found' };
    });
}

//SignIn via Authorzation URL
export async function signInIdgoAuthUrl(email, notificationType) {
  return axios
    .post(
      ctxValue('ATO_DEMO_SERVICE') + '/authenticateByIdgoQRcode',
      { email, pollForResponse:true, notificationType },
      { headers: { 'Content-Type': 'application/json' } }
    )
    .then((result) => {
      return {...result.data, status: 200};
    })
    .catch((err) => {
      if (err?.response?.status!==404) {
        console.error(`utils/services.js: signInIdgoAuthUrl() - ${err}`);
        return{ status: 500, message: 'System issue' };
      }
      return { status: 404, message: 'Account not found' };
    });
}

//SignIn authenticateStatus
export async function signInIdgoAuthStatus(verificationRequestId, timeoutSec=55) {
  return axios
    .post(
      ctxValue('ATO_DEMO_SERVICE') + '/authenticateStatus',
      { verificationRequestId, timeoutSec },
      { headers: { 'Content-Type': 'application/json' } }
    )
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.error(`utils/services.js: signInIdgoAuthStatus() - ${err}`);
      return { status: 500, message: 'System issue' };
    });
}

//SignIn IDgo
export async function signInIDGO(mobileNumber, accountNumber) {
  return axios
    .post(
      ctxValue('ATO_DEMO_SERVICE') + '/authenticateByIDGO',
      { mobileNumber, accountNumber },
      { headers: { 'Content-Type': 'application/json' } }
    )
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.error(`utils/services.js:  signInIDGO() - ${err}`);
      return { status: 500, message: 'System issue' };
    });
}

//exists IDgo
export async function exists(mobileNumber, accountNumber) {
  return axios
    .post(
      ctxValue('ATO_DEMO_SERVICE') + '/exists',
      { mobileNumber,accountNumber },
      { headers: { 'Content-Type': 'application/json' } }
    )
    .then((result) => {
      return result;
    })
    .catch((err) => {
      //Rally don't like this and will have to change it later.. but is late and i'm tired so off it goes with this...
      if(err == 'Error: Request failed with status code 404' )
      {
        return { status: 404, message: 'Account not found' };
      }
      else{
        console.error(`utils/services.js:  exists() - ${err}`);
        return { status: 500, message: 'System issue' };
      }
    });
}

//enroll IDgo
export async function enroll(accountNumber, mobileNumber, data) {
  return axios
    .post(
      ctxValue('ATO_DEMO_SERVICE') + '/enroll',
      {accountNumber, mobileNumber , data},
      { headers: { 'Content-Type': 'application/json' } }
    )
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.error(`utils/services.js:  enroll() - ${err}`);
      return { status: 500, message: 'System issue' };
      
    });
}

//Account Overview
export async function transferRequest() {
  const token = tokenVerify();
  try {
    return axios.get(ctxValue('ATO_DEMO_SERVICE') + '/getSensitiveData', {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (err) {
    console.error(`utils/services.js: getSensitiveData() - ${err}`);
    return { status: 500, message: 'System issue' };
  }
}

export async function preEnroll({mobileNumber, email, fullName, data, subdomain}){
  let options = {mobileNumber, email, fullName, data, subdomain};
 
  if (email === '' || email === null || email === undefined){
    delete options.email;
  }

  return axios
    .post(
      ctxValue('ATO_DEMO_SERVICE') + '/preEnroll',
      options,
      { headers: { 'Content-Type': 'application/json' } }
    )
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.error(`utils/services.js:  preEnroll() - ${err}`);
      return { status: 500, message: 'System issue' };
    });
}