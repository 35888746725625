// idgoAgentPopup.js - Utility library to simplify popping an IDgo Agent window and receiving the results.

let g_idgoEventMethods;
let g_idgoCallbackMessageHandler;
let g_idgoAgentWin;

/**
 * This helper function should be updated with the details of the enterprise's environment.
 * The 5 startsWith calls are for IDgo demo applications and coorespond to the returned IDgo agent URLs.
 * The startsWith URLs should be changed per enterprise to match various enterprise environments.
 */
const _getAgentHostFromHref = () => {
  const href = window.location.href;
  if (href.startsWith('http://localhost:3001'))         return 'http://localhost:3000';
  if (href.startsWith('http://localhost'))              return 'https://agent.dev.cozera.io';
  if (href.startsWith('https://demo.dev.cozera.io'))    return 'https://agent.dev.cozera.io';
  if (href.startsWith('https://demo.uat.cozera.io'))    return 'https://agent.uat.cozera.io';
  if (href.startsWith('https://demo.prod.cozera.io'))   return 'https://agent.prod.cozera.io';
  console.error('idgoAgentPopup.js: Unable to derive IDgo Agent host');
  return undefined;
};

const _centerPopupWin = (name, w, h) => {
  const left = window.screen.availLeft + (window.screen.availWidth - w) / 2;
  const top = window.screen.availTop + (window.screen.availHeight - h) / 2;
  // attempting to open a second window will cause an exception
  if (g_idgoAgentWin && g_idgoAgentWin.closed===false) {
    g_idgoAgentWin.close();
  }
  g_idgoAgentWin = window.open('', name, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  g_idgoAgentWin.document.write('Loading...');
};

/**
 * Called by a contact center web application to popup an IDgo Agent window with a user's mobile number.
 * 
 * @param {*} subdomain - Proivded by IDgo when an enterprise is deployed.
 * @param {*} mobileNumber - Mobile number of the user to pre-populate the IDgo agent popup window.
 * @param {*} handleIDgoAgentResponse - A callback function that gets the 'result' of the authentication 
 * and 'data' object with any configured data to be shown to the agent.
 * @returns 
 */
const popupIDgoAgent = (subdomain, mobileNumber, handleIDgoAgentResponse) => {
  const idgoAgentHost = _getAgentHostFromHref();
  if (!idgoAgentHost) return;

  _centerPopupWin('IDgo', 368, 340);
  g_idgoAgentWin.location = `${idgoAgentHost}/caller-queue?mobileNumber=${mobileNumber}#${subdomain}`;

  if (g_idgoCallbackMessageHandler) return;

  // Create IE + others compatible "message" event handler
  g_idgoEventMethods = window.addEventListener
    ? {add:'addEventListener', remove:'removeEventListener'}
    : {add:'attachEvent'     , remove:'detachEvent'}
    ;
  const messageEvent = (g_idgoEventMethods.add==='addEventListener') ? 'message' : 'onmessage';
  g_idgoCallbackMessageHandler = window[g_idgoEventMethods.add];

  // Listen for messages from IDgo Agent popup
  g_idgoCallbackMessageHandler(messageEvent, function(e) {

    if (!g_idgoAgentWin || !g_idgoEventMethods || !g_idgoCallbackMessageHandler) return;

    // Check if origin is proper
    if (e.origin != idgoAgentHost) { 
      //console.debug(`handleIDgoAgentPopup() bypassing unmatched origin: ${e.origin}`);  
      return;
    }

    const result = e?.data?.result;
    //console.debug(`handleIDgoAgentPopup() handling callback, result: ${result}, data: ${e?.data?.data}`);  
    handleIDgoAgentResponse(e?.data?.result, e?.data?.data);
    const messageEvent = (g_idgoEventMethods.remove==='removeEventListener') ? 'message' : 'onmessage';
    window[g_idgoEventMethods.remove](messageEvent, g_idgoCallbackMessageHandler, false);
    if (result!=='browser-closed') g_idgoAgentWin.close();  // this allows testing with ctrl-F5

    // initialize all idgo global vars (this fixes the double-callbacks and duplicate-callbacks)
    g_idgoAgentWin=undefined;
    g_idgoEventMethods=undefined;
    g_idgoCallbackMessageHandler=undefined;
  }, false);
};

export
{ popupIDgoAgent
};