import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import QRCode from 'qrcode'

import { login } from '../../utils/session';
import { signInIdgoAuthUrl, signInIdgoAuthStatus} from '../../utils/services';

import toast from 'react-hot-toast';
import styles from './SignInQRCode.module.css';

const maxWaitSec = 180 * 1000; // 3 minutes

function SignInQRCode(props) {
  const navigate = useNavigate();

  const generateQrCode = (url) => {
		QRCode.toDataURL(url, {
			width: 800,
			margin: 2,
			color: {
				dark: '#335383FF',
				light: '#EEEEEEFF'
			}
		}, (err, url) => {
			if (err) {
        toast.error(err);
        return;
      }
			props.setQrCode(url)
		})
	};

  const handleQRcodeLogin = async () => {
    const timerStart = Date.now();
    const resAuthStart = await signInIdgoAuthUrl(props.email, 'qrcode');
    if (resAuthStart?.status === 404) {
      toast.error('Your account is not protected by IDgo. Please sign-in using a password and then activate IDgo.',{duration: 7000});
      return null;
    }
    if (resAuthStart?.status !== 200) {
      toast.error(`Error: ${resAuthStart.message}`,{duration: 7000});
      return null;
    }
    generateQrCode(resAuthStart.userAuthUrl);
    getAuthStatus(resAuthStart.id, timerStart);
  };

  // this function allows us to recursivly call the auth status api to retry on 'timeout' results
  const getAuthStatus = (verificationRequestId, timerStart) => {
    signInIdgoAuthStatus(verificationRequestId)
      .then((res) => {
        const timerEnd = Date.now();
        const duration = timerEnd - timerStart;
        if (res?.data?.result==='timeout' && duration <= maxWaitSec) {
          getAuthStatus(verificationRequestId, timerStart);
        } else {
          props.setQrCode(undefined);
          handleAuthResponse(res);
        }
      })
      .catch((err) => {
        props.setQrCode(undefined);
        toast.error(`Error: ${err}`,{duration: 7000});
      });
  };

  const handleAuthResponse = (response) => {
    if (response?.status !== 200) {
      toast.error(`Error: ${response?.message}`,{duration: 7000});
      return;
    }
    if (response?.data?.result === 'rejected') {
      toast.error('Authentication declined');
      return;
    }
    if (response?.data?.result === 'timeout') {
      toast.error('Authentication timeout');
      return;
    }
    if (response?.data?.result !== 'pass') {
      toast.error('Authentication error');
      return;
    }
    login(response?.data?.accessToken);
    navigate('/accounts', {state:{homePage:props.homePage}});
  };

  return (
    <React.Fragment>
      <div className={styles.buttonWrapper}>
        <Button variant='primary' className={styles.Button} onClick={handleQRcodeLogin} disabled={props.loading || !props.validEmail}>
          <span className={styles.idgoButton}>Log in with IDgo</span>
        </Button>
      </div>
    </React.Fragment>
  );
}

export default SignInQRCode;