import React from 'react';
import { Navigate } from 'react-router-dom';

//Utils
import { haveSession } from '../../utils/session';

function PrivateRoute({ children }) {
  const auth = haveSession();
  return auth ? children : <Navigate to='/' />;
}

export default PrivateRoute;
