import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { login } from '../../utils/session';
import { signInIdgoSms } from '../../utils/services';

import toast from 'react-hot-toast';
import styles from './SignInSMS.module.css';

function SignInSMS(props) {
  const navigate = useNavigate();

  const handleSmsLogin = async () => {
    props.setLoading(true);
    const response = await signInIdgoSms(props.email);
    props.setLoading(false);
    if (response?.status === 404) {
      toast.error('Your account is not protected by IDgo. Please sign-in using a password and then activate IDgo.',{duration: 7000});
      return;
    }
    if (response?.status !== 200) {
      toast.error(`Error: ${response.message}`,{duration: 7000});
      return;
    }
    handleAuthResponse(response);
  };

  const handleAuthResponse = (response) => {
    if (response?.status !== 200) {
      toast.error(`Error: ${response?.message}`,{duration: 7000});
      return;
    }
    if (response?.data?.result === 'rejected') {
      toast.error('Authentication declined');
      return;
    }
    if (response?.data?.result === 'timeout') {
      toast.error('Authentication timeout');
      return;
    }
    if (response?.data?.result !== 'pass') {
      toast.error('Authentication error');
      return;
    }

    login(response?.data?.accessToken);
    navigate('/accounts', {state:{homePage:props.homePage}});
  };

  return (
    <React.Fragment>
      <div className={styles.buttonWrapper}>
        <Button variant='primary' className={styles.Button} onClick={handleSmsLogin} disabled={props.loading || !props.validEmail}>
          <span className={styles.idgoButton}>Log in with IDgo</span>
        </Button>
      </div>
    </React.Fragment>
  );
}

export default SignInSMS;