import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import QRCode from 'qrcode'
import { signInIdgoSms, signInIdgoAuthUrl, signInIdgoAuthStatus, preEnroll} from '../../utils/services';
import { getSession, parseJWT, logout } from '../../utils/session';
import { useNavigate, useLocation } from 'react-router-dom';
import { Spinner } from '../index';

import styles from './Accounts.module.css';

//Images
import idgoLogo from '../id-go.png';
import secure from './secure.png';
import unsecure from './unsecure.png';
import avatar from './avatar.png';
import savings from './savings.png';
import checking from './checking.png';
import visa from './visa.png';
import transfers from './transfers.png';
import deposits from './deposits.png';
import payments from './payments.png';

function Accounts() {
  const [showCompleteTransferDialog, setShowCompleteTransferDialog] = useState(false);
  const [transferAttempt, setTransferAttempt] = useState(false);
  const [showEnroll, setShowEnroll] = useState(false);
  const [showEnrollDialog, setShowEnrollDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({}); // object of user attributes
  const [qr, setQr] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const session = getSession();
    const decodedJWT = parseJWT(session.token);
    setUser(decodedJWT.user);   
    if (!decodedJWT.user.usedIDGO && !decodedJWT.user.hasIDGO) {
      setShowEnroll(true);
    }  
  }, []);

  const handleSignOut = () => {
    logout();
    navigate(location?.state?.homePage || '/');
  };

  const handleClose = () => {
    setShowCompleteTransferDialog(false);
    setShowEnrollDialog(false);
  };

  const handleShow = () => setShowCompleteTransferDialog(true);
  const handleShowEnroll = () => setShowEnrollDialog(true);

  const preenrollIDGO = async () => {
    const data = {memberID: user.accountNumber};
    const fullName = user.firstName+' '+user.lastName;
    let enrollParams = {mobileNumber: user.mobileNumber, fullName, data, subdomain: user.xsubdomain};

    const enrollResponse = await preEnroll(enrollParams);
    if (enrollResponse?.status === 201) {
      toast.success('Congratulations your account is now protected by IDgo!',{position:"bottom-center"});
    } else {
      toast.error('Enroll failed. Pleast try again.',{position:"bottom-center"});
    }
    user.hasIDGO = true;
    handleClose();
    setShowEnroll(false);
    if (transferAttempt) {
      setShowCompleteTransferDialog(true);
    }
  };

  const handleTransferRequest = async () => {
    if (user.usedIDGO) { //if they did not sign-in using idgo AND they have an idgo acct then auth using idgo
      toast.success('Transfer completed successfully', { position: "bottom-center", duration:9000 });
      return;
    } 
    if (!user.usedIDGO && user.hasIDGO) { //if they did not sign-in using idgo AND they have an idgo acct then auth using idgo
      handleShow();
      return;
    }
    if (!user.usedIdGo && !user.hasIDGO){
      setShowEnrollDialog(true);
      setTransferAttempt(true);
    }
  };

  const handleSmsLogin = async () => {
    setLoading(true);
    const response = await signInIdgoSms(user.email);
    setLoading(false);
    setShowCompleteTransferDialog(false);
    if (response?.status !== 200) {
      toast.error('Unable to send SMS.',{duration: 9000});
      return;
    }
    if (response?.data?.result === 'rejected') {
      toast.error('Authentication declined', { position: "bottom-center" });
      return;
    }
    if (response?.data?.result === 'timeout') {
      toast.error('Authentication timeout', { position: "bottom-center" });
      return;
    }
    if (response?.data?.result !== 'pass') {
      toast.error('Authentication error', { position: "bottom-center" });
      return;
    }
    toast.success('Transfer completed successfully', { position: "bottom-center" });
  };

  const handleQRcodeLogin = async () => {
    setShowCompleteTransferDialog(false);
    const resp = await signInIdgoAuthUrl(user.email, 'qrcode');
    if (resp?.status !== 200) {
      toast.error('Unable to generate QR code.',{duration: 9000});
      return;
    }
    
    generateQRCode(resp.userAuthUrl);

    const response = await signInIdgoAuthStatus(resp.id);
    setQr(undefined);
    if (response?.status !== 200) {
      toast.error('Sign in error 2.');
      return;
    }
    if (response?.data?.result === 'rejected') {
      toast.error('Authentication declined', { position: "bottom-center" });
      return;
    }
    if (response?.data?.result === 'timeout') {
      toast.error('Authentication timeout', { position: "bottom-center" });
      return;
    }
    if (response?.data?.result !== 'pass') {
      toast.error('Authentication error', { position: "bottom-center" });
      return;
    }
    toast.success('Transfer completed successfully', { position: "bottom-center" });
  };

  const generateQRCode = (url) => {
		QRCode.toDataURL(url, {width:800, margin:2, color: {dark:'#335383FF', light: '#EEEEEEFF' }}, (err, url) => {
			if (err) {
        toast.error(err);
        return;
      }
			setQr(url)
		})
	};

  const formatMobileNumber = (n) => {
    if (!n) return '';
    if (n.lenght<10) return '';
    return n.slice(-10, -7) + '.' + n.slice(-7, -4) + '.' + n.slice(-4);
  };

  return (
    <React.Fragment>
      {qr ? (
        <div className={styles.container}>
          <div className={styles.center}>
            <h4 className={styles.loginHeader}>Scan with your mobile device</h4>
            <img src={qr} width='300'/>
          </div>
        </div>

      ) : (
      
      <div className={styles.container}>
        <div className={styles.center}>

          <div className={styles.pageHeader}>
            <div className={styles.userInfo}>
              <div className={styles.userEmail}>{user.email}</div>
              <div className={styles.userMobileNumber}>{formatMobileNumber(user.mobileNumber)}</div>
            </div>
            {user.hasIDGO ? (
                <img className={styles.userIdgo} src={secure} title='Your account is secured with IDgo'></img>
              ):(
                <img className={styles.userIdgo} src={unsecure} title='Your account is not secured with IDgo'></img>
              )
            }
            <img className={styles.userAvatar} src={avatar} onClick={handleSignOut} title='Sign Out'></img>
          </div>

          <div className={styles.accountsHeader}>Accounts</div>

          <div className={styles.accountsList}>
            <button className={styles.accountRow}>
              <div className={styles.accountIcon}><img className={styles.accountIconImg} src={checking} title='Smart Checking'></img></div>
              <div className={styles.accountName}>6989* Smart Checking</div>
              <div className={styles.accountLabel}>Balance</div>
              <div className={styles.accountBalance}><span style={{color:'green'}}>$8,638.42</span></div>
            </button>
            <button className={styles.accountRow}>
              <div className={styles.accountIcon}><img className={styles.accountIconImg} src={savings} title='Student Savings'></img></div>
              <div className={styles.accountName}>8728* Student Savings</div>
              <div className={styles.accountLabel}>Balance</div>
              <div className={styles.accountBalance}><span style={{color:'green'}}>$28,830.00</span></div>
            </button>
            <button className={styles.accountRow}>
              <div className={styles.accountIcon}><img className={styles.accountIconImg} src={visa} title='Visa Cashback'></img></div>
              <div className={styles.accountName}>6989* Visa Cashback</div>
              <div className={styles.accountLabel}>Balance</div>
              <div className={styles.accountBalance}><span style={{color:'darkred'}}>$1,686.99</span></div>
            </button>
          </div>

          <div className={styles.moveMoneyHeader}>Move Money</div>

          <div className={styles.moveMoneyList}>
            <button className={styles.moveMoney} onClick={handleTransferRequest}>
              <div><img className={styles.moveMoneyImg} src={transfers} title='Transfers'></img></div>
              <div>Transfers</div>
            </button>
            <div className={styles.moveMoney}>
              <div><img className={styles.moveMoneyImg} src={deposits} title='Deposits'></img></div>
              <div>Deposits</div>
            </div>
            <div className={styles.moveMoney}>
              <div><img className={styles.moveMoneyImg} src={payments} title='Payments'></img></div>
              <div>Payments</div>
            </div>
          </div>

          {showEnroll ? (
            <>
              <div className={styles.idgoEnrollHeader}>Protect Your Account</div>

              <div className={styles.idgoAutoEnrollWrapper}>
                <button className={styles.idgoAutoEnroll} onClick={handleShowEnroll}>
                  <div>Your account is not protected by IDgo.</div>
                  <div>Find out more...</div>
                </button>
              </div>

            </>
          ) : null}

          <div></div>

          <Modal show={showCompleteTransferDialog} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Complete Transfer</Modal.Title>
            </Modal.Header>

            <Modal.Body>To complete your transfer you must authenticate using IDgo.</Modal.Body>

            <Modal.Footer>
              <div className={styles.buttonWrapper}>
                  <Button variant='primary' className={styles.Button} onClick={handleSmsLogin} disabled={loading}>
                    <span className={styles.idgoButton}>SMS</span>
                  </Button>
                </div>

                <div className={styles.buttonWrapper}>
                  <Button variant='secondary' className={styles.Button} onClick={handleQRcodeLogin} disabled={loading}>
                    <span className={styles.idgoButton}>QR code</span>
                  </Button>
                </div>

                {loading && <Spinner as="span" animation="grow" size="lg" role="status" variant="dark" /> }
            </Modal.Footer>
          </Modal>
            
          <Modal show={showEnrollDialog} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Enroll in IDgo <img src={idgoLogo} width="50" /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              What is IDgo?
              <br/><br/>
                IDgo replaces discoverable secrets like passwords and one-time passcodes with strong passwordless 
                biometric authentication so there are no secrets for attackers to steal. To protect privacy, 
                authentication is executed with device based biometrics that never leave your device.
              <br/><br/>
            </Modal.Body>
            <Modal.Footer>
             
              <div className={styles.buttonWrapper}>
                <Button variant='primary' className={styles.Button} onClick={preenrollIDGO} disabled={loading}>
                  <span className={styles.idgoButton}>Enroll now</span>
                </Button>
              </div>

              <div className={styles.buttonWrapper}>
                <Button variant='secondary' className={styles.Button} onClick={handleClose} disabled={loading}>
                  Cancel
                </Button>
              </div>

              {loading && <Spinner as="span" animation="grow" size="lg" role="status" variant="dark" /> }

            </Modal.Footer>
          </Modal>

        </div>
      </div>
     )}
    </React.Fragment>
  );
}

export default Accounts;