// addIdgoAgentListener.js - Utility library to simplify listening to an IDgo Agent iframe window and receiving the authentication results.

let g_eventListener;

const addIdgoAgentListener = (handleIDgoAgentResponse) => {
  if (g_eventListener) return;

  // Create IE + others compatible "message" event handler
  const windowMethod = (window.addEventListener) ? 'addEventListener' : 'attachEvent';
  const messageEvent = (window.addEventListener) ? 'message' : 'onmessage';
  g_eventListener = window[windowMethod];
  
  // Listen for messages
  g_eventListener(messageEvent, function(e) {
    // Filter out messages not from IDgo Agent
    if (e?.data?.source !== 'IDgoAgent') return;
    handleIDgoAgentResponse(e?.data?.result, e?.data?.data);
  }, false);
};

const removeIdgoAgentListener = () => {
  if (!g_eventListener) return;

  const windowMethod = (window.removeEventListener) ? 'removeEventListener' : 'detachEvent';
  const messageEvent = (window.removeEventListener) ? 'message' : 'onmessage';

  window[windowMethod](messageEvent, g_eventListener, false);
  g_eventListener=undefined;
};

export
{ addIdgoAgentListener
, removeIdgoAgentListener
};