import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from './AccountsPage.module.css';

//Components
import { Accounts } from '../../components/index';

function AccountsPage() {
  const { state } = useLocation();

  return (
    <div className={styles.page}>
      <Accounts homePage={state.homePage} />
    </div>
  );
}

export default AccountsPage;
