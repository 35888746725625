import * as zoid from 'zoid/dist/zoid.frameworks';

const getEnvFromHref = (href) => {
  if (href.startsWith('http://localhost'))            return 'LOCALDEV';
  if (href.startsWith('https://demo.dev.cozera.io'))  return 'DEV';
  if (href.startsWith('https://demo.uat.cozera.io'))  return 'UAT';
  if (href.startsWith('https://demo.cozera.io'))      return 'PROD';

  console.error(`IdgoAuthComponent.js: Unable to derive Environment from href: ${href}`);
  return undefined;
};

const env = getEnvFromHref(window.location.href);
console.info(`IdgoAuthComponent.js: Environment: ${env}`);

const interfaceUrlMap = 
{ 'DEV'  : 'https://auth.dev.id-go.com'
, 'UAT'  : 'https://auth.uat.id-go.com'
, 'PROD' : 'https://auth.id-go.com3'
};
const interfaceUrl = interfaceUrlMap[env] || 'http://localhost:3001';
console.info(`interfaceUrl: ${interfaceUrl}`);

const IdgoAuthComponent = zoid.create({
  // The html tag used to render my component

  tag: "idgo-auth-component",

  // The url that will be loaded in the iframe or popup, when someone includes this component on their page

  url: interfaceUrl,

  // The size of the component on their page. Only px and % strings are supported

  dimensions: {
    width: "100%",
    height: "40px",
  },

  attributes: {
    iframe: {
      allow: "publickey-credentials-get *",
      scrolling: "no"
    }
  },

  // The properties they can (or must) pass down to my component. This is optional.

  props: {
    disabled: {
      type: "boolean",
      required: false,
    },
    loginValue: {
      type: "string",
      required: false,
    },
    buttonText: {
      type: "string",
      required: false,
    },
    buttonStyles: { // stringify'ed object react named styles
      type: "string",
      required: false,
    },
    onClick: {
      type: "function",
      required: true,
    },
    onAuthenticationResult: {
      type: "function",
      required: true,
    },
  },
});

export default IdgoAuthComponent;