import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import styles from './AgentIFrame.module.css';

//Utilities
import { addIdgoAgentListener, removeIdgoAgentListener } from '../../utils/idgoAgentIFrame';

function AgentIFrame() {
  const navigate = useNavigate();
  const [ searchParams ] = useSearchParams();
  const [ iFrameUrl, setiFrameUrl ] = useState('https://agent.dev.cozera.io/caller-queue?mobileNumber=5037092934#demo1');
  const [ listenerEnabled, setListenerEnabled ] = useState(false);
  const [ authMessage, setAuthMessage ] = useState();

  useEffect(() => {
    const mobileNumber = searchParams.get('mobileNumber');
    const subdomain = searchParams.get('subdomain');
    if (mobileNumber && subdomain) {
      setiFrameUrl(`https://agent.dev.cozera.io/caller-queue?mobileNumber=${mobileNumber}#${subdomain}`);
    }

    if (listenerEnabled) return;

    addIdgoAgentListener(idgoAgentMessage);
    setListenerEnabled(true);

    return () => {
      if (!listenerEnabled) return;
      removeIdgoAgentListener();
    }
  }, [searchParams]);

  const navAgentCallbackPage = () => { navigate('/agent-callback') }

  const idgoAgentMessage = (result, data) => {
    console.log(`data: ${JSON.stringify(data,null,2)}`);
    switch (result) {
      case 'browser-closed':
        window.alert(`IDgo Agent window closed`);
        break;
      case 'pass':
        setAuthMessage(
          <>
            <div>
              <span>Authentication</span>
              <span className={styles.auth_pass}>Successful</span>
              <span>for</span>
            </div>
            <div>
              <span className={styles.auth_data_label}>{data[0].label}</span>
              <span className={styles.auth_data_value}>{data[0].value}</span>
            </div>
            <div>
              <span className={styles.auth_data_label}>{data[1].label}</span>
              <span className={styles.auth_data_value}>{data[1].value}</span>
            </div>
          </>
        );
        break;
      case 'rejected':
      case 'fail':
        setAuthMessage(<>{result}</>);
        break;
      default:
        setAuthMessage(<>Unknown result: {result}</>);
    }
  };

  return (
    <>
      <div className={styles.hostPage}>
        <div className={styles.nav} onClick={navAgentCallbackPage}>
          popup
        </div>
        <div className={styles.result}>
          <span>{authMessage}</span>
        </div>
        <iframe name="idgoAgentIFrame" src={iFrameUrl} width={354} height={332} ></iframe>
      </div>
    </>
  );
}

export default AgentIFrame;