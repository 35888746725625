import React from 'react';
import { PreEnrollSignIn } from '../../components/index';
import styles from './PreEnrollSignInPage.module.css';

function PreEnrollSignInPage() {
  return (
    <>
      <div className={styles.centerDiv}>
        <h1 className={styles.centerTextTitle}>Pre-Enrollment Demo </h1>
        <PreEnrollSignIn />
      </div>
    </>
  );
}

export default PreEnrollSignInPage;
