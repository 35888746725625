import React from 'react';
import { AgentIFrame } from '../../components/index';
import styles from './AgentIFramePage.module.css';

function AgentIFramePage() {
  return (
    <>
      <div className={styles.page}>
        <AgentIFrame />
      </div>
    </>
  );

}

export default AgentIFramePage;
