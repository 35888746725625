import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';

import { login } from '../../utils/session';
import { signInPassword} from '../../utils/services';

import toast from 'react-hot-toast';
import styles from './SignInUsernamePassword.module.css';

function SignInUsernamePassword(props) {
  const [password, setPassword] = React.useState('');
  const navigate = useNavigate();
    
  const forgotPassword = () => {
    toast.success('Forget passwords, go password-less!');
  };

  const handleEmail = (e) => {
    props.setEmail(e.target.value);
    const valid = validator.isEmail(e.target.value);
    props.setValidEmail(valid);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordLogin = async () => {
    if (!props?.email) {
      toast.success('Please enter your email.');
      return;
    }
    if (!validator.isEmail(props?.email)) {
      toast.error('Invalid email address.');
      return;
    }
    if (!password) {
      toast.success('Please enter your password.');
      return;
    }
     
    const response = await signInPassword(props?.email, password);
    if (response?.status !== 200) {
      toast.error('Invalid login. Please try again.');
      setPassword('');
      return;
    }

    login(response?.data?.accessToken);
    navigate('/accounts', {state:{homePage:props.homePage}});
  };

  return (
    <>
      <div className={styles.inputWrapper}>
        <input type='text' placeholder='Login ID' className={styles.Input} onChange={handleEmail} value={props?.email} />
      </div>

      <div className={styles.inputWrapper}>
        <input type='password' placeholder='Password' className={styles.Input} onChange={handlePassword} value={password}/>
      </div>

      <div className={styles.forgotPassword}>
        <div onClick={forgotPassword}>Forgot password?</div>
      </div>
      
      <div className={styles.buttonWrapper}>
        <Button className={styles.Button} onClick={handlePasswordLogin} disabled={props.loading || !props.validEmail || password.length===0}>
          Log In
        </Button>
      </div>
    </>
  );
}

export default SignInUsernamePassword;