import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import styles from './PreEnrollSignIn.module.css';
import { useNavigate } from 'react-router-dom';
import { login } from '../../utils/session';

//Helpers
import { signInPassword } from '../../utils/services';
import toast from 'react-hot-toast';

//Components
import { Spinner } from '../index';

function PreEnrollSignIn({ isLoading }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!email || !password) {
      toast.error('Please enter a valid email or password.');
      return;
    }

    const response = await signInPassword(email, password);
    if (response?.status!==200) {
      toast.error('Invalid login. Please try again.');
      setPassword('');
      return;
    }

    login(response?.data?.accessToken);
    navigate('/preenroll');
  };

  return (
    <React.Fragment>
      {isLoading && <Spinner />}
      <Form className={styles.FormContainer} onSubmit={handleSubmit} noValidate>
        <div className={styles.InputContainer}>
          <Form.Group className='mb-2' controlId='passwordForm.ControlInputMobileNumber'>
            <Form.Label className={styles.Label}>E-mail</Form.Label>
            <Form.Control
              type='text'
              placeholder='tester@fintech.io'
              className={styles.Input}
              onChange={handleEmail}
              value={email}
              required
            />
            <Form.Control.Feedback type='invalid'>
              Please provide a valid email.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-2' controlId='passwordForm.ControlInputPassword'>
            <Form.Label className={styles.Label}>Password</Form.Label>
            <Form.Control
              type='password'
              placeholder='Password'
              className={styles.Input}
              onChange={handlePassword}
              value={password}
              required
              autoComplete='on'
            />
          </Form.Group>
        </div>
        <Button variant='primary' type='submit' className={styles.Button}>
          Log in
        </Button>
      </Form>
    </React.Fragment>
  );
}

export default PreEnrollSignIn;
