import React from 'react';
import { AgentPopupCallback } from '../../components/index';
import styles from './AgentPopupCallbackPage.module.css';

function AgentPopupCallbackPage() {
  return (
    <>
      <div className={styles.page}>
        <AgentPopupCallback />
      </div>
    </>
  );

}

export default AgentPopupCallbackPage;
