import React from 'react';
import { PreEnroll } from '../../components/index';
import styles from './PreEnrollPage.module.css';

function PreEnrollPage() {
  return (
    <>
      <div className={styles.page}>
        <PreEnroll />
      </div>
    </>
  );

}

export default PreEnrollPage;
