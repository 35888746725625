// config.js

const getEnvFromHref = (href) => {
  if (href.startsWith('http://localhost:3000'))                 return 'DEV';
  if (href.startsWith('http://localhost:3001'))                 return 'LOCALDEV';
  if (href.startsWith('http://localhost'))                      return 'DEV';
  if (href.startsWith('https://demo.dev.cozera.io'))            return 'DEV';
  if (href.startsWith('https://demo.preenroll.dev.cozera.io'))  return 'DEV';
  if (href.startsWith('https://demo.uat.cozera.io'))            return 'UAT';
  if (href.startsWith('https://demo.preenroll.uat.cozera.io'))  return 'UAT';
  console.error('config.js: ERROR - Unable to derive Environment!');
  return undefined;
};
  
const g_env = getEnvFromHref(window.location.href);

  
const g_envConfig =
{ 'LOCALDEV': { ATO_DEMO_SERVICE    : 'http://localhost:3000'  }
, 'DEV'     : { ATO_DEMO_SERVICE    : 'https://dev.cozera-ops.net/ato-demo-service' }
, 'UAT'     : { ATO_DEMO_SERVICE    : 'https://uat.cozera-ops.net/ato-demo-service' }
};
  
export const ctxValue = (key) => 
{
  switch (key.toUpperCase()) 
  {
    case 'ENV':
      return g_env;

    default:
      if (!(g_env in g_envConfig)) {
        console.error('Error: env <' + g_env + '> not defined in g_envConfig')
        return;
      }
      if (!(key in g_envConfig[g_env])) {
        console.error('Error: key <' + key + '> not defined in g_envConfig['+g_env+']')
        return;
      }
      return g_envConfig[g_env][key];
  }
};