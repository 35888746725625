import React from 'react';
import { Route, Routes } from 'react-router-dom';

//Pages
import 
{ SignInOptionsPage
, SignInQRCodePage
, SignInSMSPage
, SignInPopupPage
, SignInFidoPage
, AccountsPage
, PreEnrollSignInPage
, PreEnrollPage
, AgentPopupCallbackPage
, AgentIFramePage
} from '../pages/index';

//Components
import PrivateRoute from '../components/routes/PrivateRoute';

function AppRouter() {
  return (
    <Routes>
      <Route path='/'                 element={<SignInOptionsPage />} />
      <Route path='/signin-qrcode'    element={<SignInQRCodePage />} />
      <Route path='/signin-sms'       element={<SignInSMSPage />} />
      <Route path='/signin-popup'     element={<SignInPopupPage />} />
      <Route path='/signin-fido'      element={<SignInFidoPage />} />

      <Route path='/accounts'         element={<PrivateRoute><AccountsPage /></PrivateRoute>}/>

      <Route path='/preenroll-signin' element={<PreEnrollSignInPage />}/>
      <Route path='/preenroll'        element={<PrivateRoute><PreEnrollPage /></PrivateRoute>}/>

      <Route path='/agent-callback'   element={<AgentPopupCallbackPage />}/>
      <Route path='/agent-iframe'     element={<AgentIFramePage />}/>
    </Routes>
  );
}

export default AppRouter;
