import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { login } from '../../utils/session';
import { signInIdgoAuthUrl, signInIdgoAuthStatus } from '../../utils/services';

import toast from 'react-hot-toast';
import styles from './SignInPopup.module.css';

const maxWaitSec = 180 * 1000; // 3 minutes

function SignInPopup(props) {
  const navigate = useNavigate();
  const [authUrl, setAuthUrl] = useState(undefined);
    
  const centerPopupWin = (name, w, h) => {
    const left = window.screen.availLeft + (window.screen.availWidth - w) / 2;
    const top = window.screen.availTop + (window.screen.availHeight - h) / 2;
    const authWin = window.open('', name, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
    authWin.document.write('<h2 style="color:darkgreen;">Authentication initiated...</h2>');
    return authWin;
  };

  // To enable Safari (iOS & MAC) to open a popup window this function must not be async
  const handleIdgoLoginByPopup = () => {
    const authWin = centerPopupWin('IDgo', 400, 600);
    const timerStart = Date.now();
    signInIdgoAuthUrl(props.email, 'popup')
      .then((res) => {
        if (res?.status === 404) {
          authWin.close();
          toast.error('Your account is not protected by IDgo. Please sign-in using a password and then activate IDgo.',{duration: 7000});
          return;
        }
        if (res?.status !== 200) {
          authWin.close();
          toast.error(`Error: ${res.message}`,{duration: 7000});
          return;
        }
        setAuthUrl(res.userAuthUrl);
        authWin.location = res.userAuthUrl;
        getAuthStatus(authWin, res.id, timerStart);
      })
      .catch((err) => {
        toast.error(`Error: ${err}`,{duration: 7000});
        authWin.close();
      });
  };

  // this function allows us to recursivly call the auth status api to retry 'timeout' result
  const getAuthStatus = (authWin, verificationRequestId, timerStart) => {
    signInIdgoAuthStatus(verificationRequestId)
      .then((res) => {
        const timerEnd = Date.now();
        const duration = timerEnd - timerStart;
        if (res?.data?.result==='timeout' && duration <= maxWaitSec) {
          getAuthStatus(authWin, verificationRequestId, timerStart);
        } else {
          setAuthUrl(undefined);
          authWin.close();
          handleAuthResponse(res);
        }
      })
      .catch((err) => {
        setAuthUrl(undefined);
        authWin.close();
        toast.error(`Error: ${err}`,{duration: 7000});
      });
  };

  const handleAuthResponse = (res) => {
    if (res?.status !== 200) {
      toast.error(`Error: ${res?.message}`,{duration: 7000});
      return;
    }
    if (res?.data?.result === 'rejected') {
      toast.error('Authentication declined');
      return;
    }
    if (res?.data?.result === 'timeout') {
      toast.error('Authentication timeout');
      return;
    }
    if (res?.data?.result !== 'pass') {
      toast.error('Authentication error');
      return;
    }

    login(res?.data?.accessToken);
    navigate('/accounts', {state:{homePage:props.homePage}});
  };

  return (
    <React.Fragment>
      <div className={styles.buttonWrapper}>
        <Button variant='primary' className={styles.Button} onClick={handleIdgoLoginByPopup} disabled={authUrl || props.loading || !props.validEmail}>
          <span className={styles.idgoButton}>{ authUrl ? 'processing...' : 'Log in with IDgo'}</span>
        </Button>
      </div>
    </React.Fragment>
  )
}

export default SignInPopup;