import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './SignInOptionsPage.module.css';

//Images
import sms from './sms.png';
import qrcode from './qr-code.png';
import popup from './popup.png';
import fido from './fido.png';

function SignInOptions() {
  const navigate = useNavigate();

  const navSignInSMSPage    = () => { navigate('/signin-sms') }
  const navSignInQRCodePage = () => { navigate('/signin-qrcode') }
  const navSignInPopupPage  = () => { navigate('/signin-popup') }
  const navSignInFidoPage   = () => { navigate('/signin-fido') }

  return (
    <>
      <div className={styles.box}>
        <h1>IDgo online &quot;Log In&quot; demos</h1>
        <div className={styles.cards}>

          <div className={styles.card} onClick={navSignInSMSPage}>
            <figure className={styles.cardThumb}>
              <img src={sms} alt='SMS link' width="85"/>
            </figure>
            <div>
              <h2 className={styles.cardTitle}>SMS link</h2>
              <p className={styles.cardExcerpt}>
                SMS required:<br /><b>YES</b>
              </p>
              <p className={styles.cardExcerpt}>
                Mobile Device Required:<br /><b>YES</b>
              </p>
              <p className={styles.cardExcerpt}>
                Devices Required:<br /><b>1</b>
              </p>
              <p className={styles.cardExcerpt}>
                Touches / clicks:<br /><b>5</b>
              </p>
              <p className={styles.cardExcerpt}>
                Integration Effort:<br /><b>Simple</b>
              </p>
            </div>
          </div>

          <div className={styles.card} onClick={navSignInQRCodePage}>
            <figure className={styles.cardThumb}>
              <img src={qrcode} alt='QR code' />
            </figure>
            <div>
              <h2 className={styles.cardTitle}>QR Code</h2>
              <p className={styles.cardExcerpt}>
                SMS required:<br /><b>NO</b>
              </p>
              <p className={styles.cardExcerpt}>
                Mobile Device Required:<br /><b>YES</b>
              </p>
              <p className={styles.cardExcerpt}>
                Devices Required:<br /><b>2</b>
              </p>
              <p className={styles.cardExcerpt}>
                Touches / clicks:<br /><b>5</b>
              </p>
              <p className={styles.cardExcerpt}>
                Integration Effort:<br /><b>Simple</b>
              </p>
            </div>
          </div>

          <div className={styles.card} onClick={navSignInPopupPage}>
            <figure className={styles.cardThumb}>
              <img src={popup} alt='Pop up' width="90"/>
            </figure>
            <div>
              <h2 className={styles.cardTitle}>Popup</h2>
              <p className={styles.cardExcerpt}>
                SMS required:<br /><b>NO</b>
              </p>
              <p className={styles.cardExcerpt}>
                Mobile Device Required:<br /><b>NO</b>
              </p>
              <p className={styles.cardExcerpt}>
                Devices Required:<br /><b>1</b>
              </p>
              <p className={styles.cardExcerpt}>
                Touches / clicks:<br /><b>2</b>
              </p>
              <p className={styles.cardExcerpt}>
                Integration Effort:<br /><b>Simple</b>
              </p>
              <p className={styles.cardExcerpt}>
                <span>Note:&nbsp;</span>Popup window is fully managed. No fumbling between windows/tabs required by the user. No browser blocking.
              </p>
            </div>
          </div>

          <div className={styles.card} onClick={navSignInFidoPage}>
            <figure className={styles.cardThumb}>
            <img src={fido} alt='FIDO' />
            </figure>
            <div>
              <h2 className={styles.cardTitle}>FIDO</h2>
              <p className={styles.cardExcerpt}>
                SMS required:<br /><b>NO</b>
              </p>
              <p className={styles.cardExcerpt}>
                Mobile Device Required:<br /><b>NO</b>
              </p>
              <p className={styles.cardExcerpt}>
                Devices Required:<br /><b>1</b>
              </p>
              <p className={styles.cardExcerpt}>
                Touches / clicks:<br /><b>1</b>
              </p>
              <p className={styles.cardExcerpt}>
                Integration Effort:<br /><b>Medium</b>
              </p>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default SignInOptions;