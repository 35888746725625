import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SignInUsernamePassword, SignInFido, Spinner } from '../../components/index';
import validator from 'validator';
import Cookies from 'js-cookie';

import styles from './SignInFidoPage.module.css';
//Images
import backButtonImg from '../../assets/chevron-back-circle-outline.png';

const homePage = '/signin-fido';
const lastSignedInEmail = Cookies.get('lastSignedInEmail') || '';

function SignInFidoPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [validEmail, setValidEmail] = useState(validator.isEmail(lastSignedInEmail));
  const [email, setEmail] = useState(lastSignedInEmail);

  const goHome = () => {
    navigate('/');
  };

  const renderContent = () => {
    return <>
      <h4 className={styles.loginHeader}>
        <img className={styles.backButton} src={backButtonImg} title='Back' onClick={goHome}></img>
        <span>Enterprise 1</span>
      </h4>
      <h6 className={styles.loginHeader2}>COMMUNITY CREDIT UNION</h6>
      <SignInUsernamePassword
        homePage={homePage}
        loading={loading} setLoading={setLoading}
        email={email} setEmail={setEmail}
        validEmail={validEmail} setValidEmail={setValidEmail}
      />
      <div className={styles.orLoginWith}>Or</div>
      <SignInFido
        homePage={homePage}
        loading={loading} setLoading={setLoading}
        email={email}
        validEmail={validEmail}
      />
    </>
  };

  return (
    <>
      {loading && <Spinner />}
      <div className={styles.container}>
        <div className={styles.center}>
          <form className={styles.loginBox}>
            { renderContent() }
          </form>
        </div>
      </div>
    </>
  );
}

export default SignInFidoPage;