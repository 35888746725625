import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SignInUsernamePassword, SignInQRCode, Spinner } from '../../components/index';
import validator from 'validator';
import Cookies from 'js-cookie';

import styles from './SignInQRCodePage.module.css';
//Images
import backButtonImg from '../../assets/chevron-back-circle-outline.png';

const homePage = '/signin-qrcode';
const lastSignedInEmail = Cookies.get('lastSignedInEmail') || '';

function SignInQRCodePage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [validEmail, setValidEmail] = useState(validator.isEmail(lastSignedInEmail));
  const [email, setEmail] = useState(lastSignedInEmail);
  const [qrCode, setQrCode] = useState(undefined);

  const goHome = () => {
    navigate('/');
  };

  const renderContent = () => {
    if (qrCode) return renderQrCode();
    return renderSignInForm();
  };

  const renderSignInForm = () => {
    return <>
      <h4 className={styles.loginHeader}>
        <img className={styles.backButton} src={backButtonImg} title='Back' onClick={goHome}></img>
        <span>Enterprise 1</span>
      </h4>
      <h6 className={styles.loginHeader2}>COMMUNITY CREDIT UNION</h6>
      <SignInUsernamePassword
        homePage={homePage}
        loading={loading} setLoading={setLoading}
        email={email} setEmail={setEmail}
        validEmail={validEmail} setValidEmail={setValidEmail}
      />
      <div className={styles.orLoginWith}>Or</div>
      <SignInQRCode
        homePage={homePage}
        loading={loading} setLoading={setLoading}
        qrCode={qrCode} setQrCode={setQrCode}
        email={email}
        validEmail={validEmail}
      />
    </>
  };

  const renderQrCode = () => {
    return <>
      <div>
        <h4 className={styles.loginHeader}>Scan with your mobile device</h4>
        <img src={qrCode} width='300'/>
      </div>
    </>
  };

  return (
    <>
      {loading && <Spinner />}
      <div className={styles.container}>
        <div className={styles.center}>
          <form className={styles.loginBox}>
            { renderContent() }
          </form>
        </div>
      </div>
    </>
  );
}

export default SignInQRCodePage;